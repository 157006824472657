<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">调度</h1>
    </div>
    <!--<div slot="extra">
      <a-button @click="handleDamageReportAdd" icon="plus" type="primary" style="margin-left: 10px">上报登记</a-button>
    </div>-->
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="状态">
              <a-radio-group @change="selectHandleStatus" v-model="searchParams.handleStatus">
                <a-radio-button value="UNPROCESSANDCONFIRMCOMPLETE">全部</a-radio-button>
                <a-radio-button value="UN_PROCESS">待处理</a-radio-button>
                <a-radio-button value="PROCESSINGANDCONFIRMCOMPLETE">已处理</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="人员名称">
              <a-input v-model="searchParams.fromName" @change="search" placeholder="人员名称"/>
            </a-form-item>
            <a-form-item label="上报时间">
              <a-range-picker @change="dateChange" v-model="dateSearch">
                <a-icon slot="suffixIcon" type="calendar"/>
              </a-range-picker>
            </a-form-item>
            <a-form-item label="绑定工单">
              <a-radio-group @change="selectStatus" v-model="searchParams.bind">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value=true>已绑定</a-radio-button>
                <a-radio-button value=false>未绑定</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
              <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">
                {{ expand ? '收起' : '展开' }}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-form-item>
            <a-row v-if="expand" style="margin-top: 10px;margin-bottom: 10px;">
              <a-form-item label="调度中心处理时间">
                <a-range-picker @change="dispatchDateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>
              <a-form-item label="上报类型">
                <a-radio-group
                  v-model="searchParams.type" @change="search">
                  <a-radio-button value="TEL">
                    电话上报
                  </a-radio-button>
                  <a-radio-button value="WECHAT">
                    微信上报
                  </a-radio-button>
                  <a-radio-button value="OTHER">
                    其他途径
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="上报原因">
                <a-input v-model="searchParams.reason" @change="search" placeholder="上报原因"/>
              </a-form-item>
              <a-form-item label="值班人员">
                <a-input v-model="searchParams.officer" @change="search" placeholder="值班人员"/>
              </a-form-item>
              <a-form-item label="来电地址">
                <a-input v-model="searchParams.fromAddress" @change="search" placeholder="来电地址"/>
              </a-form-item>
              <a-form-item label="来电电话">
                <a-input v-model="searchParams.fromPhone" @change="search" placeholder="来电电话"/>
              </a-form-item>
              <!--<a-form-item label="处理完成时间">
                <a-range-picker @change="handleCompleteDateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>
              <a-form-item label="回访时间">
                <a-range-picker @change="feedbackDateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>
              <a-form-item label="确认时间">
                <a-range-picker @change="confirmDateChange" v-model="dateSearch">
                  <a-icon slot="suffixIcon" type="calendar"/>
                </a-range-picker>
              </a-form-item>-->
            </a-row>
            <a-row :gutter="[16,16]">
              <a-spin :spinning="spinning">
                <a-col :sm="24" :md="12" :lg="8" :xl="4">
                  <a-card>
                    <a-statistic
                      title="总数"
                      :value="pagination.total"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="4">
                  <a-card>
                    <a-statistic
                      title="待调度"
                      :value="damageStatistics.UN_PROCESS"
                      :valueStyle="valueStyle"
                      style="text-align: center;">
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="4">
                  <a-card>
                    <a-statistic
                      title="处理中"
                      :value="damageStatistics.PROCESSING"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="4">
                  <a-card>
                    <a-statistic
                      title="待回访"
                      :value="damageStatistics.PROCESSED"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="4">
                  <a-card>
                    <a-statistic
                      title="待确认"
                      :value="damageStatistics.FEEDBACK_COMPLETE"
                      :valueStyle="valueStyle"
                      style="text-align: center; color: #1890ff;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="4">
                  <a-card>
                    <a-statistic
                      title="已完成"
                      :value="damageStatistics.CONFIRM_COMPLETE"
                      :valueStyle="valueStyle"
                      style="text-align: center; color: #1890ff;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
              </a-spin>
            </a-row>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :customRow="handleView"
        :scroll="{ x: 1400 }"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="type" slot-scope="text, record">
          <a-tag v-if="record.type==='TEL'" color="#00BFFF">
            <a-icon type="phone"/>&nbsp;&nbsp;电话上报
          </a-tag>
          <a-tag v-if="record.type==='WECHAT'" color="#00CD66">
            <a-icon type="wechat"/>&nbsp;&nbsp;微信上报
          </a-tag>
          <a-tag v-if="record.type==='OTHER'" color="#00CED1">
            <a-icon type="calendar"/>&nbsp;&nbsp;其他途径
          </a-tag>
        </template>
        <template slot="bind" slot-scope="text, record">
          <a-tag v-if="record.bind===true" color="#1874CD">
            已绑定
          </a-tag>
          <a-tag v-if="record.bind===false || record.bind===null" color="#BEBEBE">
            未绑定
          </a-tag>
        </template>
        <template slot="handleStatus" slot-scope="text, record">
          <a-tag v-if="record.handleStatus==='UN_PROCESS'" color="red">
            待调度
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSING'" color="blue">
            待处理
          </a-tag>
          <a-tag v-if="record.handleStatus==='PROCESSED'" color="#996600">
            待回访
          </a-tag>
          <a-tag v-if="record.handleStatus==='FEEDBACK_COMPLETE'" color="#cc9909">
            待确认
          </a-tag>
          <a-tag v-if="record.handleStatus==='CONFIRM_COMPLETE'" color="#3A5FCD">
            已完成
          </a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <a v-if="record.handleStatus==='UN_PROCESS'" @click.stop="handleDispatch(record.id)">调度</a>
          <a v-if="record.handleStatus==='PROCESSING'||record.handleStatus==='PROCESSED'||record.handleStatus==='FEEDBACK_COMPLETE'||record.handleStatus==='CONFIRM_COMPLETE'"
             href="javascript:return false;" onclick="return false;" style="color: #c5c2bf;" @click.stop>已调度</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleDelete(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      title="电话调度"
      :visible="addModal"
      :maskClosable="true"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal">
      <register-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></register-form>
    </a-modal>
    <!--調度Modal-->
    <a-modal
      title="调度" width="800px"
      :visible="dispatchModal"
      :maskClosable="true"
      centered
      @ok="dispatchAddOk"
      @cancel="closeDispatchModal">
      <dispatch ref="dispatchRef" type="add" @dispatchSuccess="dispatchSuccess"
                @dispatchError="dispatchError"></dispatch>
    </a-modal>
    <!--回訪Modal-->
    <a-modal
      title="回访"
      :visible="feedbackModal"
      :maskClosable="true"
      centered
      @ok="feedbackAddOk"
      @cancel="closeFeedbackModal">
      <feedback ref="feedbackRef" type="add" @feedbackSuccess="feedbackSuccess"
                @feedbackError="feedbackError"></feedback>
    </a-modal>
    <!--确认Modal-->
    <a-modal
      title="确认"
      :visible="confirmModal"
      :maskClosable="true"
      centered
      @ok="confirmAddOk"
      @cancel="closeConfirmModal">
      <confirm ref="confirmRef" type="add" @confirmSuccess="confirmSuccess"
               @confirmError="confirmError"></confirm>
    </a-modal>
    <a-drawer
      title="上报详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="600px"
      :visible="detailDrawer"
      :mask="false">
      <damage-report-detail ref="viewRef"></damage-report-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { damageReportDispatchColumns, reportTypeList, processStatusList } from './common/common'
  import PageLayout from '@/components/page/PageLayout'
  import RegisterForm from './Form'
  import Dispatch from './Dispatch'
  import Feedback from './Feedback'
  import Confirm from './Confirm'
  import DamageReportDetail from './Detail'
  import entityCRUD from '../../common/mixins/entityCRUD'

  export default {
    name: 'List',
    components: { RegisterForm, PageLayout, Dispatch, Feedback, Confirm, DamageReportDetail },
    mixins: [entityCRUD],
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.ticket.damageReport,
        detailDrawer: false,
        addModal: false,
        dispatchModal: false,
        feedbackModal: false,
        confirmModal: false,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          fromName: '',
          fromPhone: '',
          fromAddress: '',
          departmentId: '',
          startFromTime: '',
          endFromTime: '',
          reason: '',
          type: '',
          officer: '',
          startDispatchTime: '',
          endDispatchTime: '',
          startHandleCompleteTime: '',
          endHandleCompleteTime: '',
          startFeedbackTime: '',
          endFeedbackTime: '',
          startConfirmTime: '',
          endConfirmTime: '',
          handleStatus: 'UN_PROCESS',
          bind: ''
        },
        initColumns: damageReportDispatchColumns(),
        tableData: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
        },
        expand: false,
        dateSearch: null, //日期绑定数据
        processStatusList: processStatusList(),
        reportTypeList: reportTypeList(),
        damageStatistics: {},
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        damageReportId: '' //当前点击记录ID
      }
    },
    mounted () {
      this.search()
    },
    created () {
      this.getStatisticsData()
      this.search()
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    methods: {
      selectHandleStatus () {
        this.search()
      },
      selectStatus () {
        this.search()
      },
      getStatisticsData () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.ticket.damageReport.statistics,
          noTips: true,
          success: (data) => {
            this.damageStatistics = data.body
            this.spinning = false
          }
        })
      },
      deleteSucceed () {
        this.getStatisticsData()
        this.search()
      },
      dateChange (date, dateString) {
        this.searchParams.startFromTime = dateString[0]
        this.searchParams.endFromTime = dateString[1]
        this.search()
      },
      dispatchDateChange (date, dateString) {
        this.searchParams.startDispatchTime = dateString[0]
        this.searchParams.endDispatchTime = dateString[1]
        this.search()
      },
      handleCompleteDateChange (date, dateString) {
        this.searchParams.startHandleCompleteTime = dateString[0]
        this.searchParams.endHandleCompleteTime = dateString[1]
        this.search()
      },
      feedbackDateChange (date, dateString) {
        this.searchParams.startFeedbackTime = dateString[0]
        this.searchParams.endFeedbackTime = dateString[1]
        this.search()
      },
      confirmDateChange (date, dateString) {
        this.searchParams.startConfirmTime = dateString[0]
        this.searchParams.endConfirmTime = dateString[1]
        this.search()
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      handleDamageReportAdd () {
        this.addModal = true
      },
      //上报
      handleAddOk () {
        this.$refs['addRef'].submitAdd()
      },
      closeAddModal () {
        this.addModal = false
      },
      addSuccess () {
        this.addModal = false
        this.$message.success('登记成功！')
        this.search()
        this.getStatisticsData()
      },
      addError () {
        this.$message.warning('登记失败！')
        this.addModal = false
      },
      //调度
      handleDispatch (id) {
        this.dispatchModal = true
        this.damageReportId = id
        this.$nextTick(() => {
          this.$refs['dispatchRef'].loadData(id)
          this.$refs['dispatchRef'].dispatchPerson()
        })
      },
      dispatchAddOk () {
        this.$refs['dispatchRef'].dispatch(this.damageReportId)
      },
      closeDispatchModal () {
        this.dispatchModal = false
      },
      dispatchSuccess () {
        this.dispatchModal = false
        this.$message.success('调度成功！')
        this.search()
        this.getStatisticsData()
      },
      dispatchError () {
        this.$message.warning('调度失败！')
      },
      //回访
      handleFeedback (id) {
        this.feedbackModal = true
        this.damageReportId = id
      },
      feedbackAddOk () {
        this.$refs['feedbackRef'].feedback(this.damageReportId)
      },
      closeFeedbackModal () {
        this.feedbackModal = false
      },
      feedbackSuccess () {
        this.feedbackModal = false
        this.$message.success('回访成功！')
        this.search()
        this.getStatisticsData()
      },
      feedbackError () {
        this.$message.warning('回访失败！')
        this.feedbackModal = false
      },
      //确认
      handleConfirm (id) {
        this.confirmModal = true
        this.damageReportId = id
      },
      confirmAddOk () {
        this.$refs['confirmRef'].confirm(this.damageReportId)
        this.confirmModal = false
      },
      closeConfirmModal () {
        this.confirmModal = false
      },
      confirmSuccess () {
        this.confirmModal = false
        this.$message.success('确认成功！')
        this.search()
        this.getStatisticsData()
      },
      confirmError () {
        this.$message.warning('确认失败！')
        this.confirmModal = false
      },
      toggle () {
        this.expand = !this.expand
      }
    }
  }
</script>
