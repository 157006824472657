<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['departmentId', { rules: [{ required: true, message: '所属部门不能为空' }] }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          @change="handleDepartment"
          placeholder="请选择所属部门"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="值班电话" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['officePhone']"
                 placeholder="请先选择所属部门"
                 disabled></a-input>
      </a-form-item>
      <a-form-item label="调度中心值班人" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['officer',{ rules: [{ required: true, message: '值班人不能为空' }] }]"
                 placeholder="请输入调度中心值班人"></a-input>
      </a-form-item>
      <a-form-item label="调度中心处理意见" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4" v-decorator="['resolution']" placeholder="请输入处理意见"></a-input>
      </a-form-item>
      <a-form-item label="类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group @change="handleTypeChange"
                       v-decorator="['handleType',{initialValue:'NO', rules: [{ required: true, message: '请选择工单类型' }] }]">
          <a-radio-button value="NO">无工单</a-radio-button>
          <a-radio-button value="NEW">下发新工单</a-radio-button>
          <a-radio-button value="OLD">绑定已有工单</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="是否派车" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group @change="handleStatusChange" v-decorator="['handleCarStatus', {
                      rules:[{required:true}],
                      trigger:'change',valuePropName:'checked',initialValue:true}]">
          <a-radio value="YES">派车</a-radio>
          <a-radio value="NO">无需派车</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="标题" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="this.handleType==='NEW'">
        <a-input v-decorator="['title',{ rules: [{ required: true, message: '标题不能为空' }] }]"
                 placeholder="请输入标题"></a-input>
      </a-form-item>
      <a-form-item label="描述" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="this.handleType==='NEW'">
        <a-input v-decorator="['description',
        { rules: [{ required: true, message: '请输入描述' }] }]" type="textarea" rows="4" placeholder="请输入描述"></a-input>
      </a-form-item>
      <a-form-item label="工单信息" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="this.handleType==='OLD'">
        <a-select allowClear showSearch placeholder="请选择工单信息" v-decorator="['ticketInfoId',
        { rules: [{ required: true, message: '请选择已有工单' }] }]" @select="selectTicketInfoId">
          <a-select-option
            v-for="item in ticketInfoData"
            :key="item.id"
            :value="item.id"
            :label="item.title">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--<a-form-item label="现场处理意见" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="this.handleType==='NO'">
        <a-input type="textarea" rows="4" v-decorator="['handleResult']" placeholder="请输入现场处理意见"></a-input>
      </a-form-item>-->
    </a-form>
  </a-spin>
</template>
<script>
  import { damageReportFormVO, departmentPhoneNumbers } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import { ticketInfoVO } from '../all/common/ticketInfoVO'

  export default {
    name: 'DispatchForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        form: this.$form.createForm(this),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        ifSub: false,
        formItem: damageReportFormVO(),
        handleType: 'NO',
        handleCarStatus: 'NO',
        ticketInfoData: [],
        ticketInfoFormItem: ticketInfoVO(),
        ticketInfoId: '', //绑定工单ID,
        orgTree: [],
        departmentPhoneNumbers: departmentPhoneNumbers()
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    created () {
      this.getSelectData()
      this.getOrgTree()
    },
    methods: {
      dispatchPerson () {
        this.form.setFieldsValue({
          'officer': this.currentUser.name
        })
      },
      getSelectData () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.ticket.info.select,
          noTips: true,
          success: (data) => {
            this.ticketInfoData = data.body
            this.spinning = false
          }
        })
      },
      setVOFields (values) {
        this.formItem.department.id = values.departmentId
        Object.assign(this.formItem, values)
      },
      setTicketInfoVOFields (values) {
        this.ticketInfoFormItem.ticketInfo.title = values.title
        this.ticketInfoFormItem.ticketInfo.ticketSource = 'REPORT'
        this.ticketInfoFormItem.ticketInfo.description = values.description
      },
      dispatch (id) {
        if (this.handleType === 'NEW' || this.handleType === 'OLD') {
          this.formItem.bind = true
        } else if (this.handleType === 'NO') {
          this.formItem.bind = false
        }
        if (this.handleCarStatus === 'NO') {
          this.formItem.dispatchStatus = 'NO_NEED'
        } else if (this.handleCarStatus === 'YES') {
          this.formItem.dispatchStatus = 'UN_DISPATCH'
        }
        this.formItem.handleStatus = 'PROCESSING'
        //创建一个新工单
        if (this.handleType === 'NEW') {
          this.form.validateFields(
            (err, values) => {
              if (!err) {
                this.ticketInfoFormItem.ticketInfo.instanceId = id
                this.setTicketInfoVOFields(values)
                this.$http(this, {
                  url: SERVICE_URLS.ticket.info.create,
                  data: this.ticketInfoFormItem,
                  noTips: true,
                  success: (data) => {
                    this.handleDispatchInner(id)
                    //清空表单数据
                    this.ticketInfoFormItem = ticketInfoVO()
                    this.form.resetFields()
                    //添加成功，清空表单数据，并触发回调
                    // _this.$emit('addSuccess')
                  },
                  error: () => {
                    this.$emit('addError')
                  }
                })
              }
            })
        } else if (this.handleType === 'OLD') { //工单绑定故障信息
          this.$http(this, {
            url: SERVICE_URLS.ticket.info.update,
            params: { id: this.ticketInfoId },
            data: {
              instanceId: id,
              ticketSource: 'REPORT'
            },
            noTips: true,
            success: (data) => {
              //清空表单数据
              this.ticketInfoId = ''
              this.handleDispatchInner(id)
            },
            error: () => {
              this.$emit('dispatchError')
            }
          })
          //现场处理完成
        } else if (this.handleType === 'NO') {
          this.handleDispatchInner(id)
        }
      },
      selectTicketInfoId (value) {
        this.ticketInfoId = value
      },
      handleTypeChange (e) {
        this.handleType = e.target.value
      },
      handleStatusChange (e) {
        this.handleCarStatus = e.target.value
      },
      handleDispatchInner (id) {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.ticket.damageReport.update,
                params: { id: id },
                data: this.formItem,
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = damageReportFormVO()
                  this.form.resetFields()
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('dispatchSuccess')
                },
                error: () => {
                  this.$emit('dispatchError')
                }
              })
            }
          })
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.ticket.damageReport.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            const departmentId = data.body.damageReportRecord.department.id
            this.$nextTick(() => {
              this.form.setFieldsValue({
                departmentId: departmentId,
                officePhone: this.getOfficePhone('' + departmentId),
              })
            })
          }
        })
      },
      handleDepartment (value) {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            departmentId: value,
            officePhone: this.getOfficePhone(value)
          })
        })
      },
      getOfficePhone (value) {
        let officePhone = ''
        this.departmentPhoneNumbers.forEach(item => {
          if (value === item.value) {
            officePhone = item.label
          }
        })
        return officePhone
      }
    }
  }
</script>

<style scoped></style>